import Categories from './categories/store';
import Tags from './tags/store';
import Publishers from './publishers/store';
import Quotes from './quotes/store';
import Authors from './authors/store';
import Books from './books/store';
import CompleteBooks from './complete_books/store';
import BookSeries from './bookseries/store';
import Experts from './experts/store';
import Skills from './skills/store';
import SkillsV2 from './skillsV2/store';
import B2BPaths from './b2b_paths/store';
import Podcasts from './podcasts/store';
import Articles from './articles/store';
import TheUpdate from './the_update/store';
import Speakers from './speakers/store';
import Copywriters from './copywriters/store';
import Quiz from './quiz/store';
import Shorts from './shorts/store';

export default {
    init(standardStore){
        return {
            namespaced: true,
            state(){
                return {}
            },
            getters: {},
            mutations: {},
            actions: {},
            modules: {
                Categories: Categories.init(standardStore),
                Tags: Tags.init(standardStore),
                Authors: Authors.init(standardStore),
                Quotes: Quotes.init(standardStore),
                Publishers: Publishers.init(standardStore),
                Books: Books.init(standardStore),
                BookSeries: BookSeries.init(standardStore),
                Experts: Experts.init(standardStore),
                Skills: Skills.init(standardStore),
                SkillsV2: SkillsV2.init(standardStore),
                Podcasts: Podcasts.init(standardStore),
                Articles: Articles.init(standardStore),
                TheUpdate: TheUpdate.init(standardStore),
                Speakers: Speakers.init(standardStore),
                Copywriters: Copywriters.init(standardStore),
                CompleteBooks: CompleteBooks.init(standardStore),
                B2BPaths: B2BPaths.init(standardStore),
                Quiz: Quiz.init(standardStore),
                Shorts: Shorts.init(standardStore),
            }
        }
    },

}
