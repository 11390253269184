const List = () => import('../../views/cont/books/chatbot/PromptList.vue');
const Edit = () => import('../../views/cont/books/chatbot/PromptEdit.vue');


export default {
    List: {
        path: 'chatbots/coach/prompts',
        name: 'chatbots_coach_prompt_list',
        exact: true,
        component: List,
        meta: {
            breadcrumb: [{ label: 'CHATBOTS' }, { label: 'Personal Coach' }, { label: 'Prompts' }],
        },
    },
    Create: {
        path: 'chatbots/coach/prompts/new',
        name: 'chatbots_coach_prompt_new', //CHANGE
        exact: true,
        component: Edit,
        props: { id: 0 },
        meta: {
            basePath: 'chatbots/coach/prompts',
            breadcrumb: [
                { label: 'CHATBOTS' },
                { label: 'Personal Coach' },
                { label: 'Prompts', to: '/app/chatbots/coach/prompts' },
                { label: 'Nuovo' }],
        },
    },
    Update: {
        path: 'chatbots/coach/prompts/:id',
        name: 'chatbots_coach_prompt_edit', //CHANGE
        exact: true,
        props: (route) => {
            return {
                id: route.params.id
            };
        },
        component: Edit,
        meta: {
            basePath: '/app/cont/categories',
            breadcrumb: [
                { label: 'CHATBOTS' },
                { label: 'Personal Coach' },
                { label: 'Prompts', to: '/app/chatbots/coach/prompts' },
                { label: 'Modifica Prompt' }],
        },
    },
}
