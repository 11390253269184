const Chat = () => import('../../views/cont/books/chatbot/Chatbot');
const StreamingChat = () => import('../../views/cont/books/chatbot/StreamingChatbot');


export default {
    Chat: {
        path: 'chatbots/coach',
        name: 'chatbots_coach_chat',
        exact: true,
        component: Chat,
        meta: {
            breadcrumb: [{ label: 'CHATBOTS' }, { label: 'Personal Coach' }, { label: 'Test Chat' }],
        },
    },
    StreamingChat: {
        path: 'streaming_chatbots/coach',
        name: 'streaming_chatbots_coach_chat',
        exact: true,
        component: StreamingChat,
        meta: {
            breadcrumb: [{ label: 'CHATBOTS' }, { label: 'Personal Coach' }, { label: 'Test Streaming Chat' }],
        },
    },
}
