import http from '../../../libs/http';

export default {
    init(){
        return {
            namespaced: true,
            state: {
                loading: false,
                streaming: false,
                messageHistory: [],
                currentMessage: null,
                error: null,
            },
            getters: {
                loading: state => state['loading'],
                streaming: state => state['streaming'],
                messageHistory: state => state['messageHistory'],
                chat: state => {
                    const res = [...state.messageHistory];
//                    console.log("PART CHAT", res, state.messageHistory);
                    if (state.currentMessage) {
                        res.push(state.currentMessage);
                    }
                    return res;
                },
                error: state => state.error
            },
            mutations: {
                startLoading(state){
                    state['loading'] = true;
                },
                stopLoading(state){
                    state['loading'] = false;
                },
                startStreaming(state){
                    state.streaming = true;
                },
                stopStreaming(state){
                    state.streaming = false;
                },
                resetHistory(state){
                    state.messageHistory = [];
                    state.currentMessage = null;
                },
                addMessageToHistory(state, message){
                  state.messageHistory.push(message);
                  state.currentMessage = null;
//                  console.log("ADDED", state.messageHistory);
                },
                updateCurrentMessage(state, message){
                    if (!message) {
                        return;
                    }
                    if (!message['role']) {
                        return;
                    }
                    if (state.currentMessage === null){
                        state.currentMessage = {
                            msg: message.content,
                            owner: message.role.toUpperCase(),
                            blinks: true,
                            references: message['references']
                        };
                        return;
                    }

                    state.currentMessage.msg = message.content;
                    if (message.completed) {
                        state.currentMessage.ts = new Date();
                        state.currentMessage['references'] = message['references'];
                        state.currentMessage.blinks = false;
                    }
                },
                setError(state, message){
                    state.error = message;
                },
                updateHistory(state){
                    if (state.currentMessage){
                        state.messageHistory.push({
                            ...state.currentMessage,
                            blinks: false,
                        });
                        state.currentMessage = null;
                    }
                }

            },
            actions: {
                createConversation(context){
                    context.commit('startLoading');
                    return new Promise((resolve, reject) => {
                        return http.chatbotClient.post("/v1/conversations", {})
                            .then(res => {
                                if(res && res.data && res.data['conversation']){
                                    resolve(res.data['conversation']);
                                    return;
                                }
                                reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                            })
                            .catch(err => {
                            reject(http.formatError('server-error', err));
                        })
                            .finally(() => {
                                context.commit('stopLoading');
                            });
                    })
                },
                sendMessage(context, {message, conversation_id}){
                    context.commit('startLoading');
                    const payload = {
                        text: message,
                        return_meta: [
                            'book_id', 'book', 'chapter_id', 'chapter_title'
                        ]
                    };
                    return new Promise((resolve, reject) => {
                        return http.chatbotClient.post(
                            "/v1/conversations/:ID".replace(":ID", conversation_id),
                            payload, {
                                params: {
                                    lang: "it"
                                }
                            })
                            .then(res => {
                                if(res && res.data && res.data){
                                    resolve(res.data);
                                    return;
                                }
                                reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                context.commit('stopLoading');
                            });
                    })
                },
                sendStreamingMessage(context, {message, conversation_id}){
                    context.commit('startLoading');
                    context.commit('setError', null);
                    const payload = {
                        q: message,
                        rm: [
                            'book_id', 'book', 'chapter_id', 'chapter_title'
                        ]
                    };
                    const ep  = ":BASEURL/v1/streaming/conversations/:ID"
                        .replace(":ID", conversation_id)
                        .replace(":BASEURL", http.api.getChatbotUrl) + '?' + http.api.encodeGetParams(payload);
                    const evtSource = new EventSource(ep, {});
                    context.commit('startStreaming');
                    evtSource.onmessage = message => {
                        const data = JSON.parse(message.data);
                        let msg = data;
                        if (data['message']){
                            msg = data['message'];
                        }
                        if (data['references'] && data['references'].length){
                            msg['references'] = data['references'];
                        }
                        context.commit('updateCurrentMessage', msg);
                        if (msg['completed']){
                            context.commit('stopLoading');
                            context.commit('stopStreaming');
                            //Add to history while removing from current
                            context.commit('updateHistory');
//                            console.log('data (last)', data);
//                            console.log('message', msg);
                            evtSource.close();
                        }
                    }
                    evtSource.onerror = error => {
                        console.log("error", error);
                        context.commit('stopLoading');
                        context.commit('stopStreaming');
                        context.commit('setError', error);
                        evtSource.close();
                    }
                }
            }
        }
    }
}
