import http from '../../../libs/http';

const config = {
    ep: 'v1/book_shorts',
    reqEl: 'short',
    respEls: 'shorts',
    respEl: 'short',
    startLoading: 'startLoading',
    stopLoading: 'stopLoading',
    storeRows: 'setRows',
    storeRow: 'setRow',
    localeKey: 'locale',
};

function getUrl( concept ) {
    let url = config.ep + "/" + concept["book_id"] + "/" + concept["lang"];
    if (concept["id"] > 0) {
        url += "/" + concept["id"];
    }
    return url;
}

const commonResponseHandler = (context, resolve, reject, res) => {
    if (res && res.data && res.data[config.respEl]) {
        resolve(res.data[config.respEl]);
        context.commit('setRow', res.data[config.respEl]);
        return;
    }
    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
}

export default {
    init(standardStore) {
        return {
            namespaced: true,
            state: {
                ...standardStore.state,
                activeShorts: {},
                inactiveShorts: {},
            },
            getters: {
                ...standardStore.getters,
                shorts: state => {
                    return {
                        ...state['activeShorts'],
                        ...state['inactiveShorts'],
                    };

                },
                activeShorts: state => state['activeShorts'],
                inactiveShorts: state => state['inactiveShorts'],
            },
            mutations: {
                ...standardStore.mutations,
                setActiveShorts(state, values) {
                    const lines = {};
                    if (values && values.length > 0) {
                        values.forEach(row => {
                            lines[row] = 1;
                        });
                    }
                    state.activeShorts = lines;
                },
                setInactiveShorts(state, values) {
                    const lines = {};
                    if (values && values.length > 0) {
                        values.forEach(row => {
                            lines[row] = 1;
                        });
                    }
                    state.inactiveShorts = lines;
                },
                addInactiveShort(state, book_id) {
                    state.inactiveShorts[book_id] = 1;
                },
            },
            actions: {
                ...standardStore.actions(config),
                SaveBookShortConcept(context, concept){
                    context.commit(config.startLoading);
                    return new Promise((resolve, reject) => {
                        const ep = getUrl(concept);
                        const remoteCall = concept["id"] === 0 ? http.api.client.post : http.api.client.put;
                        remoteCall(ep, {concept: concept})
                            .then(res => commonResponseHandler(context, resolve, reject, res))
                            .catch(err => {
                            reject(http.formatError('server-error', err));
                        })
                            .finally(() => {
                                context.commit(config.stopLoading);
                            });
                    });
                },
                DeleteBookShortConcept(context, concept){
                    context.commit(config.startLoading);
                    return new Promise((resolve, reject) => {
                        const ep = getUrl(concept);
                        http.api.client.delete(ep)
                            .then(res => commonResponseHandler(context, resolve, reject, res))
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                context.commit(config.stopLoading);
                            });
                    });
                },
                GetShortsOnBook(context, { book_id, lang }) {
                    context.commit(config.startLoading);
                    return new Promise((resolve, reject) => {
                        http.api.client.get(config.ep + '/' + book_id + '/' + lang)
                            .then(res => {
                                if (res && res.data && res.data[config.respEl]) {
                                    resolve(res.data[config.respEl]);
                                    context.commit('setRow', res.data[config.respEl]);
                                    return;
                                }
                                reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                context.commit(config.stopLoading);
                            });
                    });
                },
                ListActive(context, lang) {
                    context.commit(config.startLoading);
                    if (lang === '') {
                        lang = 'it';
                    }
                    return new Promise((resolve, reject) => {
                        http.api.client.post(config.ep + '/searches', {
                            light: true,
                            lang: lang,
                            active: 1,
                        })
                            .then(res => {
                                if (res && res.data && res.data['books']) {
                                    context.commit('setActiveShorts', res.data['books']);
                                    resolve(res.data['books']);
                                    return;
                                }
                                reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                context.commit(config.stopLoading);
                            });
                    });
                },
                ListInactive(context, lang) {
                    context.commit(config.startLoading);
                    if (lang === '') {
                        lang = 'it';
                    }
                    return new Promise((resolve, reject) => {
                        http.api.client.post(config.ep + '/searches', {
                            light: true,
                            lang: lang,
                            active: -1,
                        })
                            .then(res => {
                                if (res && res.data && res.data['books']) {
                                    context.commit('setInactiveShorts', res.data['books']);
                                    resolve(res.data['books']);
                                    return;
                                }
                                reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                context.commit(config.stopLoading);
                            });
                    });
                },
            },
        };
    },
};
