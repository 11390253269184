import HelpDesk from './helpdesk/store';
import CoachBot from './coach/store';
import CoachPrompts from './coach/prompts';

export default {
    init(standardStore){
        return {
            namespaced: true,
            state(){
                return {}
            },
            getters: {},
            mutations: {},
            actions: {},
            modules: {
                HelpDesk: HelpDesk.init(standardStore),
                CoachPrompts: CoachPrompts.init(standardStore),
                Coach: CoachBot.init()
            }
        }
    }
}
