const List = () => import('../../views/cont/shorts/List');
const Edit = () => import('../../views/cont/shorts/Edit');

export default {
    List: {
        path: 'cont/shorts',
        name: 'cont_shorts_list',
        exact: true,
        component: List,
        meta: {
            basePath: '/app/shorts/quiz',
            breadcrumb: [{ label: 'CONTENUTI' }, { label: 'Shorts sui Libri' }],
        },
    },
    Update: {
        path: 'cont/shorts/:id/:lang',
        name: 'cont_shorts_edit', //CHANGE
        exact: true,
        props: (route) => {
            return {
                id: route.params.id,
                lang: route.params.lang || 'it'
            };
        },
        component: Edit,
        meta: {
            basePath: '/app/cont/shorts',
            breadcrumb: [
                { label: 'CONTENUTI' },
                { label: 'Shorts', to: '/app/cont/shorts'},
                { label: 'Modifica Shorts' }],
        },
    },
}
