
const config = {
    ep: "/v1/chatbots/prompts",
    reqEl: "prompt",
    respEls: "prompts",
    respEl: "prompt",
    startLoading: "startLoading",
    stopLoading: "stopLoading",
    storeRows: "setRows",
    storeRow: "setRow",
    localeKey: "locale",
    methods: {
        savePlain: 'put'
    }
};

export default {
    init(standardStore){
        return {
            namespaced: true,
            state: { ...standardStore.actions },
            mutations: {...standardStore.mutations },
            getters: {...standardStore.getters },
            actions: {
                ...standardStore.actions(config)
            }
        }
    }
}
